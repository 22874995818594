import { rest } from 'msw';
import { ConfigurationResponse } from '../types';

const defaultResponse: ConfigurationResponse = 
{ // DEFAULT CONFIG: Only applies when API is down.
    loaded: false,
    domainBaseUrl: 'https://dev.openskycc.com',
    storageAccountBaseUrl: 'https://steus2prodopensky002.blob.core.windows.net',
    electronicDisclosureDocumentPath: '/files/electronic_disclosure_consent_statement.pdf',
    privacyPolicyDocumentPath: '/files/Privacy-Notice-Disclosure-2024-October.pdf',
    privacyNoticeDocumentPath: '/files/Capital-Bank-Online-Privacy-Policy-rev-10-2024.pdf',
    //incentiveName: 'DepositReductionAmount', // 'MinimumDepositAmount', //'MinimumDepositAmount', 'DepositReductionAmount',//'TenPercentOffFullyFunded',
    securedProducts: [
        {
            id: 1,
            productType: '990',
            system: '3893',
            prn: '1000',
            description: 'Secured',
            productName: 'OpenSky Secured Visa® Credit Card',
            minimumCreditLimit: 200,
            minimumDeposit: 200,
            maximumCreditLimit: 3000,
            offerCode: {
                id: 7,
                chaCode: 'O058',
                annualPercentageRate: '22.14',
                chaDocumentPath: '/files/ca-o-066.pdf',
                termsAndConditionsDocumentPath: '/files/tc-sky1-066.pdf'
            },            
            incentives: [
                    {
                        id: '1234-5678-90AB-CDEF',
                        name: 'OpenSky Nov-23 Basic Promotion Deposit Reduction',
                        productTypeId: 1,
                        productTypeName: '',
                        startDateUtc: '11-1-2023 00:00:00',
                        endDateUtc: '11-30-2023 00:00:00',
                        isActive: true,
                        incentiveDetails: [
                            {
                                incentiveTypeId: 1,
                                incentiveTypeName: 'DepositReductionAmount', //MinimumDepositAmount, DepositReductionAmount
                                value: '51'
                            },
                        ]
                    }
            ]                    
        },
        {
            id: 5,
            productType: '990',
            system: '3893',
            prn: '9000',
            description: 'Secured Plus',
            productName: 'OpenSky Secured Plus Visa® Credit Card',
            minimumCreditLimit: 300,
            minimumDeposit: 300,
            maximumCreditLimit: 3000,
            offerCode: {
                id: 8,
                chaCode: 'P005',
                annualPercentageRate: '29.49',
                chaDocumentPath: '/files/ca-p-011.pdf',
                termsAndConditionsDocumentPath: '/files/tc-sky1p-011.pdf'
            },            
            incentives: [
                    {
                        id: '456-7890-12AB-CDEF',
                        name: 'OpenSky Nov-23 Plus Promotion Minimum Deposit',
                        productTypeId: 5,
                        productTypeName: '',
                        startDateUtc: '11-1-2023 00:00:00',
                        endDateUtc: '11-30-2023 00:00:00',
                        isActive: true,
                        incentiveDetails: [                           
                            {
                                incentiveTypeId: 2,
                                incentiveTypeName: 'MinimumDepositAmount', //MinimumDepositAmount, DepositReductionAmount
                                value: '200'
                            },
                        ]
                    }
            ]            
        },
        {
            id: 9,
            productType: '990',
            system: '9999',
            prn: '6100',
            description: 'Launch',
            productName: 'OpenSky Launch Visa® Credit Card',
            minimumCreditLimit: 100,
            minimumDeposit: 100,
            maximumCreditLimit: 1000,
            offerCode: {
                id: 9,
                chaCode: 'P005',
                annualPercentageRate: '29.99',
                chaDocumentPath: '/files/ca-l-005.pdf',
                termsAndConditionsDocumentPath: '/files/tc-sky1l-005.pdf'
            },            
            //incentives: [
            //]            
        },
    ],        
};

const downsellResponse = {
    domainBaseUrl: "https://dev.openskycc.com",
    storageAccountBaseUrl: "https://steus2devopensky002.blob.core.windows.net",
    electronicDisclosureDocumentPath: "/files/electronic_disclosure_consent_statement.pdf",
    privacyPolicyDocumentPath: "/files/Privacy-Notice-Disclosure-2024-October.pdf",
    privacyNoticeDocumentPath: "/files/Capital-Bank-Online-Privacy-Policy-rev-10-2024.pdf",
    incentiveName: null,
    featureFlags: [
      {
        featureName: "SecuredPlus",
        isEnabled: true
      }
    ],
    securedProducts: [
      {
        id: 1,
        productType: "990",
        system: "3893",
        prn: "1000",
        description: "Secured",
        productName: "OpenSky Secured Visa® Credit Card",
        minimumCreditLimit: 200,
        maximumCreditLimit: 3000,
        offerCode: {
          id: 14,
          chaCode: "O066",
          annualPercentageRate: "25.64",
          chaDocumentPath: "/files/ca-o-066.pdf",
          termsAndConditionsDocumentPath: "/files/tc-sky1-066.pdf"
        },
        incentives: []
      },
      {
        id: 5,
        productType: "990",
        system: "3893",
        prn: "9000",
        description: "Secured Plus",
        productName: "OpenSky Secured Plus Visa® Credit Card",
        minimumCreditLimit: 300,
        maximumCreditLimit: 3000,
        offerCode: {
          id: 15,
          chaCode: "P007",
          annualPercentageRate: "29.99",
          chaDocumentPath: "/files/ca-p-007.pdf",
          termsAndConditionsDocumentPath: "/files/tc-sky1p-007.pdf"
        },
        incentives: [
            {
              id: "f6900767-a23e-4866-81f2-f89d5bd05b17",
              name: "Downsell",
              productType: {
                id: 1,
                code: "990",
                name: null
              },
              utmSource: {
                id: 1,
                code: "0001",
                name: "Capital Bank - Open Sky Card"
              },
              utmMedium: {
                id: 2,
                code: "15",
                name: "retargeting_ad"
              },
              utmCampaign: {
                id: 1,
                name: "Acquisition_Lookalike",
                isActive: true
              },
              startDateUtc: "2024-07-29T00:00:00",
              endDateUtc: "2024-08-02T00:00:00",
              isActive: true,
              incentiveDetails: [
                {
                  incentiveType: {
                    id: 3,
                    name: "Downsell"
                  },
                  operator: 0,
                  value: "0",
                  unit: 0,
                  incentiveTypeId: 3,
                  incentiveTypeName: "Downsell"
                }
              ]
            }
          ]
      },
      {
        id: 6,
        productType: "990",
        system: "3893",
        prn: "6100",
        description: "Secured Launch",
        productName: "OpenSky Launch® Secured Visa Credit Card",
        minimumCreditLimit: 100,
        maximumCreditLimit: 1000,
        offerCode: {
          id: 16,
          chaCode: "L001",
          annualPercentageRate: "29.99",
          chaDocumentPath: "/files/ca-l-001.pdf",
          termsAndConditionsDocumentPath: "/files/tc-sky1l-001.pdf"
        },
        incentives: []
      }
    ]
  }
  
export const configuration = [
    rest.get(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/configuration`, (req, res, ctx) => {
        const isDownsell = req.url.searchParams.get('downsell') === 'true';
        
        const response = isDownsell ? downsellResponse : defaultResponse;

        return res(
            ctx.status(200),
            ctx.json(response)
        );       
    }),
];
